@import (reference)
  "node_modules/@quantive/ui-kit/src/less/default/default.less";

.busy-indicator-progress-color {
  color: @busy-indicator-progress-color;
}

.loader-container {
  display: flex;
  align-items: center;

  &-top {
    flex-direction: column-reverse;
    .ant-spin-text {
      margin-bottom: 10px;
    }
  }

  &-bottom {
    flex-direction: column;
    .ant-spin-text {
      margin-top: 10px;
    }
  }

  &-left {
    flex-direction: row-reverse;
    .ant-spin-text {
      margin-right: 10px;
    }
  }

  &-right {
    flex-direction: row;
    .ant-spin-text {
      margin-left: 10px;
    }
  }
}

.ant-spin {
  [ui-icon] {
    font-size: @spin-icon-size;
  }

  .ant-spin-text {
    font-family: @loading-indicator-font-family-spinning-text;
    font-weight: @loading-indicator-weight-spinning-text;
    color: @gray-color-9;
    font-size: @font-size-base;
    line-height: normal;
  }

  &-lg {
    [ui-icon] {
      font-size: @spin-icon-size-lg;
    }

    .ant-spin-text {
      font-size: @font-size-lg;
    }
  }

  &-sm {
    [ui-icon] {
      font-size: @spin-icon-size-sm;
    }

    .ant-spin-text {
      font-size: @font-size-sm;
    }
  }
}

.ant-spin.spin-linear {
  width: 100%;

  .linear-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 4px;
    width: 100%;

    .linear-loading {
      display: block;
      width: 100%;
      height: 4px;
      background-color: transparent;
      border-radius: 2px;
      position: relative;

      .bar {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-color: @busy-indicator-progress-color;
        animation: progress 3s ease-in-out infinite;
      }
    }
  }
}

@keyframes progress {
  0% {
    width: 0;
  }

  50% {
    width: 50%;
  }

  100% {
    width: 100%;
  }
}
