/* overriding h1-h6 margins to cover AngularJS requirements */

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0px;
}

// The default display is flex, but it constrains the width of the dropdowns
.cdk-overlay-pane {
  display: unset;
}
