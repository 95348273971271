@import (reference) "packages/webapp/src/less/base/mixins/theme.less";

a,
button {
  &.ui-link {
    .ui-link-base();
    position: relative;
    display: inline-block;
    height: @height-xs;
    line-height: @height-xs;
    font-family: "SF Pro Text", sans-serif;

    &.ui-small {
      height: @height-xxs;
      line-height: @height-xxs;
      font-size: @font-size-sm;
    }

    &.ui-large {
      height: @height-xs + 2px;
      line-height: @height-xs + 2px;
      font-size: @font-size-lg;
    }
  }
}

button.ui-link {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  background: transparent;
}
