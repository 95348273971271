@import (reference)
  "node_modules/@quantive/ui-kit/src/less/default/default.less";

.ant-input {
  border: 1px solid @gray-color-4;
  border-radius: @border-radius-md;
  background: @gray-color-1;
  color: @gray-color-13;
  box-shadow: @input-box-shadow;
}
.ant-input::-moz-placeholder {
  opacity: 1;
}
.ant-input::placeholder {
  color: @gray-color-9;
}
.ant-input:hover {
  border: 1px solid @gray-color-6;
}
.ant-input:focus,
.ant-input-focused {
  border: 1px solid @primary-color-8;
  box-shadow: @input-box-shadow;
  outline: 0;
}
.ant-input-affix-wrapper > input.ant-input {
  box-shadow: none !important;
}
.ant-input[disabled] {
  color: @gray-base;
  background: @gray-color-2;
  cursor: not-allowed;
}
.ant-input[disabled]::placeholder {
  color: @gray-color-5;
}
.ant-input[readonly] {
  color: @gray-color-13;
  background: @gray-color-2;
}
.ant-input[readonly]::placeholder {
  color: @gray-color-9;
}
.ant-input-lg {
  padding: 9px 11px;
  font-size: 14px;
}
.ant-input-borderless,
.ant-input-borderless:hover,
.ant-input-borderless:focus,
.ant-input-borderless-focused,
.ant-input-borderless-disabled,
.ant-input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none !important;
}

.ant-input-status-error:focus,
.ant-input-status-error:active,
.ant-input-status-error.ant-input-focused {
  box-shadow: @input-box-shadow;
  outline: 0;
}
.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input:hover {
  background: @gray-color-1;
  border-color: @error-color;
  box-shadow: @input-box-shadow !important;
  outline: 0;
}
