@import (reference)
  "node_modules/@quantive/ui-kit/src/less/default/default.less";

.ui-scrollable-content-shadow-top:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 8px;
  z-index: 1;
  pointer-events: none;
  background: linear-gradient(
    @gray-color-3 10%,
    fade(@gray-color-9, 10%) 10% 15%,
    rgba(255, 255, 255, 0)
  );
}

.ui-scrollable-content-shadow-bottom:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 8px;
  z-index: 1;
  pointer-events: none;
  background: linear-gradient(
    rgba(255, 255, 255, 0) 10%,
    fade(@gray-color-9, 10%) 80% 90%,
    @gray-color-3 90%
  );
}
