@import (reference)
  "node_modules/@quantive/ui-kit/src/less/default/default.less";

custom-field-select,
.cf-select-container {
  line-height: 36px;

  .cf-select-option {
    padding: 4px 14px;
    border-radius: 16.5px;
    line-height: 16px;
    font-size: 12px;
    color: @text-color;

    &:hover {
      .cf-selected-option-down-arrow[ui-icon] {
        visibility: hidden;
      }
    }
  }

  .ant-select-multiple.ant-select-disabled.ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    background-color: @gray-color-1;
  }

  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 24px;
  }
}

custom-field-select {
  .cf-select-empty-option {
    background-color: @gray-color-5;
    color: @gray-color-1;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 24px;
    padding: 0px;
    border-radius: 16.5px;
    border: none;
  }

  .ant-select-single {
    &.ant-select-open {
      .ant-select-selection-item {
        opacity: 0.6;
        color: initial; // ant override
      }
    }
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    box-shadow: 0 0 0 1px @primary-color-8; // ant override
  }
}

.cf-select-container {
  .cf-select-list-option-container {
    display: flex;
    align-items: center;
  }

  .ant-select-item-option-content {
    flex: initial; // ant override
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    font-weight: initial; // ant override
    background-color: initial; // ant override
  }
}

.cdk-virtual-scroll-content-wrapper {
  transform: unset !important;
}
