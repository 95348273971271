@import (reference)
  "node_modules/@quantive/ui-kit/src/less/default/default.less";

custom-field-multi-select,
.cf-multi-select-container {
  .ant-select-multiple {
    .ant-select-selection-item {
      display: flex;
      align-items: center;
      border: 0;
      margin-top: @chip-margin;
      margin-bottom: @chip-margin;
      height: @chip-height;
      border-radius: @chip-border-radius;
      background-color: @gray-color-2;
      padding-inline-start: 8px;
      padding-inline-end: 8px;
    }

    .ant-select-selection-item-remove {
      color: @gray-color-7;
      font-size: @chip-icon-size;
      line-height: 0; // ant override
    }

    .ant-select-selection-placeholder {
      color: @gray-color-7;
      font-size: @placeholder-font-size-legacy;
    }
  }
  &.borderless {
    .ant-select-multiple {
      .ant-select-selector:not(.ant-select-customize-input) {
        border: @border-width-base @border-style-base @gray-color-1;
        box-shadow: none;
      }
    }
  }

  .ant-select-multiple.ant-select-disabled.ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    background-color: @gray-color-1;
  }
}

custom-field-multi-select {
  width: 100%;

  .ant-select {
    width: 100%;
  }

  .ant-select-multiple {
    .ant-select-selector:not(.ant-select-customize-input) {
      height: auto;
      min-height: @height-lg;
      color: @gray-color-13;
      border: @border-width-base @border-style-base @gray-color-4;
      border-radius: 4px;
      box-shadow: none;
      transition: none;

      &:hover,
      &:focus,
      &:focus-within {
        border: @border-width-base @border-style-base @gray-color-4;
        box-shadow: 0px 1px 1px rgba(90, 128, 158, 0.1);
      }

      &:hover {
        border-color: @gray-color-6;
      }

      &:focus {
        border-color: @primary-color-8;
      }

      &:focus-within {
        border-color: @primary-color-8;
      }
    }
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    box-shadow: none; // ant override
  }
}
