@import (reference)
  "node_modules/@quantive/ui-kit/src/less/default/default.less";

.ant-radio-group {
  display: flex;
  font-size: @font-size-base;

  &.horizontal {
    flex-direction: row;
  }
  &:not(.horizontal) {
    flex-direction: column;
  }

  .ant-radio-button-wrapper {
    border-color: @gray-color-3;
    font-size: 13px;
    font-weight: @font-weight-medium;

    &::before {
      &:extend(.bg-color-gray-3);
    }

    &:first-child {
      border-radius: @border-radius-md 0 0 @border-radius-md;
    }

    &:last-child {
      border-radius: 0 @border-radius-md @border-radius-md 0;
    }

    &:hover {
      &:extend(.bg-color-gray-2);
      &:extend(.text-color-gray-10);
    }

    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      border-color: @gray-color-5;
      &:extend(.text-color-gray-10);

      &::before {
        &:extend(.bg-color-gray-5);
      }
    }
  }

  .ant-radio-wrapper {
    margin-bottom: @margin-md;

    &::after {
      content: none;
    }

    .ant-radio {
      .ant-radio-input {
        margin: 0;
      }

      .ant-radio-input:focus + .ant-radio-inner {
        box-shadow:
          0 0 0 1px @white,
          0 0 0 2px @blue-base;
      }

      &:hover {
        .ant-radio-inner {
          border-color: @gray-9;
        }
      }

      .ant-radio-inner {
        border-color: @gray-base;
        box-shadow: inset 0 1px 2px rgba(90, 127, 158, 0.2);
        transition: none;

        &::after {
          transition: none;
          transform: scale(0.9);
        }
      }

      &.ant-radio-checked {
        .ant-radio-inner {
          border-color: @primary-color;
          background-color: @primary-color;

          &::after {
            background-color: @white;
          }
        }

        & + span {
          font-weight: @font-weight-medium;
        }

        &:hover {
          .ant-radio-inner {
            border-color: @blue-10;
            background-color: @blue-10;
          }
        }
      }

      &.ant-radio-disabled {
        .ant-radio-inner {
          background-color: @white;
          border-color: @gray-4 !important;
        }

        & + span {
          color: @gray-base;
        }

        &.ant-radio-checked {
          .ant-radio-inner {
            border-color: @gray-4 !important;
            background-color: @gray-4 !important;
          }
        }
      }
    }

    span.ant-radio + * {
      font-weight: @font-weight-regular;
      font-size: @font-size-base;
      color: @gray-13;
    }
  }
}

.ant-radio-inner::after {
  top: 3px;
  left: 3px;
  width: 8px;
  height: 8px;
  margin: 0;
}
