@import (reference)
  "node_modules/@quantive/ui-kit/src/less/default/default.less";
@import (reference) "../../../less/base/mixins/components/_focus.less";

@modal-container-padding: @padding-md + @padding-lg;
@close-icon-size: 16px;
@close-icon-min-width: 32px;
@cdk-overlay-container-overwritten-z-index: 1100;

.ant-modal-close {
  &:extend(.text-color-gray-9);

  position: unset;
  min-width: @close-icon-min-width;
  border-radius: @border-radius-md;
  padding: @padding-xs;

  &:hover {
    background: fade(@gray-color-9, 8%);
  }

  &:focus-visible {
    .focused-element-outline();
  }

  .ant-modal-close-x {
    width: @close-icon-size;
    height: @close-icon-size;
    line-height: initial;
    display: inline-block;
  }
}

.ui-modal-option-button {
  position: absolute;
  top: 0;
  right: @close-icon-min-width + 15px;
}

.cdk-overlay-container {
  // (Rusi) to do: this z-index is greater than UIB modal z-index and seems to be added because of NG-zorro modal being opened on top of UIB modal
  // (Rusi) this causes all UIB modals to appear below NG-zorro modals/drawers and has to be removed once we use NG-zorro modals only
  z-index: @cdk-overlay-container-overwritten-z-index;
}

.ant-modal {
  .ant-modal-content {
    .header-wrapper {
      position: relative;
      margin: 0 @margin-md @margin-md @margin-md;
      min-height: calc(2 * @padding-xs + @close-icon-size + @padding-md);

      > .ant-modal-close {
        position: absolute;
      }
    }
  }
}

.ant-modal:not(.ant-modal-confirm) {
  width: fit-content;

  &.display-flex {
    display: flex;
  }

  .ant-modal-side-content {
    pointer-events: auto;
  }

  .ant-modal-content {
    z-index: 2;
    padding: @padding-md 0;
    color: @gray-color-11;

    .ant-modal-header {
      padding: 0;
      border-bottom: none;

      .ant-modal-title {
        h4 {
          font-size: @modal-header-title-font-size;
          line-height: @modal-header-title-line-height;
          font-weight: @font-weight-bold;
          color: @gray-color-13;
          word-break: break-word;
        }
      }
    }

    .ant-modal-body {
      font-size: 16px;
      padding: 0;
    }

    .body-wrapper {
      padding: 0 @modal-container-padding;
    }

    .ant-modal-footer {
      border-top: none;
      display: flex;
      align-items: start;
      gap: @padding-md;
      padding: 0 @modal-container-padding @padding-lg @modal-container-padding;
      margin-top: @margin-md;

      .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
        margin-left: 0;
      }
    }

    > ui-loading-indicator {
      padding: 0 40px;
      display: block;
    }

    > ui-alert {
      display: block;
      padding: 0 40px;
    }
  }

  &.content-position-default {
    .ant-modal-title {
      padding: @padding-lg @padding-xl 0 @padding-lg;
    }
  }

  &.content-position-centered {
    text-align: center;

    .ant-modal-title {
      padding: @padding-lg @padding-lg 0 @padding-lg;
    }

    .ant-modal-footer {
      justify-content: center;
    }
  }
}

.ant-modal-title {
  color: @gray-color-13;
}

.ant-modal-mask {
  background-color: fade(@gray-color-10, 64);
}

.ant-modal-content {
  position: unset;
  border-radius: 8px;
  box-shadow: 0 6px 12px 0 fade(@gray-color-9, 30);
}

.no-padding {
  .ant-modal-content {
    padding: 0 !important;
    width: auto !important;

    .ant-modal-body {
      border-radius: inherit;

      .body-wrapper {
        padding: 0 !important;
        border-radius: inherit;
      }
    }
  }
}
