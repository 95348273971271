@import (reference) "../../../../../less/base/mixins/theme";

[class^="ui-hint-"],
[class*=" ui-hint-"] {
  font-size: 12px;
  line-height: 16px;
  pointer-events: none;

  a {
    pointer-events: auto;
  }

  &.ui-hint-success {
    &:extend(.text-color-success-10);
  }
  &.ui-hint-info {
    &:extend(.text-color-gray-9);
  }
  &.ui-hint-warning {
    &:extend(.text-color-warning-10);
  }
  &.ui-hint-error {
    &:extend(.text-color-error-10);
  }

  &.accented {
    &:extend(.text-color-gray-10);

    &.ui-hint-success {
      background-color: @success-color-2;
    }
    &.ui-hint-info {
      &:extend(.text-color-gray-10);
      background-color: @gray-color-3;
    }
    &.ui-hint-warning {
      background-color: @warning-color-3;
    }
    &.ui-hint-error {
      background-color: @error-color-3;
    }
  }
}
