.ant-popover {
  border-radius: 4px;
  padding-top: 2px;

  .ant-popover-inner {
    border-radius: 4px;

    .ant-popover-title {
      border-bottom: none;
      padding: 0;
    }

    .ant-popover-inner-content {
      padding: 0;
    }
  }

  &.without-arrow {
    .ant-popover-content {
      .ant-popover-arrow {
        display: none;
      }
    }
  }
}
