@import (reference)
  "node_modules/@quantive/ui-kit/src/less/default/default.less";

.gh-unit-selector-container {
  width: 100%;

  .form-item {
    display: flex;
    margin-bottom: 0;
    width: 100%;

    .field-label {
      flex: 1;
      text-align: start;
      vertical-align: middle;

      label {
        color: @gray-color-13;
        font-weight: @font-weight-regular;
      }
    }

    .field-input.ant-form-item-control {
      flex-direction: row;
      flex: initial;

      .ant-form-item-control-input {
        width: 100%;
      }
    }

    .ant-select.ant-select-single {
      width: 100%;
      height: @height-lg;

      .ant-select-selector {
        padding-right: 30px;
        border-radius: @border-radius-md;
        height: inherit;
        border: @border-width-base @border-style-base @gray-color-4;
        box-shadow: 0px 1px 1px 0px rgba(90, 128, 158, 0.1);
        transition: initial;

        &:hover {
          border-color: @gray-color-6;
        }

        &:focus-within {
          border-color: @primary-color-8;
        }

        .ant-select-selection-item {
          height: inherit;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

.unit-option-select-container .ant-select-dropdown,
.fraction-size-option-select-container .ant-select-dropdown {
  ui-option-item {
    padding: 8px;

    &.ant-select-item-option-selected {
      background-color: @primary-color-8;

      .ant-select-item-option-content {
        color: @gray-color-13;
        font-weight: @font-weight-medium;
      }
    }

    .ant-select-item-option-content .fraction-size-example {
      font-weight: @font-weight-medium;
      opacity: 0.75;
    }
  }
}
