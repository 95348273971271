@import "node_modules/@quantive/ui-kit/src/less/default/default.less";

.ant-drawer-content-wrapper {
  width: auto;
  display: flex;

  &.goal-side-panel,
  &.metric-side-panel {
    .side-panel {
      display: flex;
      box-shadow: none;
    }
  }

  &.goal-history-side-panel {
    .ant-drawer-body {
      padding: 32px;
    }
  }

  .ant-drawer-content {
    width: var(--drawer-width);
  }

  .side-panel {
    height: 100%;
    position: relative;
    z-index: 1;
    pointer-events: auto;
    overflow: auto;
    background-color: #fff;
    background-clip: padding-box;
    box-shadow: 0 4px 10px rgba(63, 87, 110, 0.3);
  }
}

.ant-drawer-body {
  padding: 0;
}

.ant-drawer-header {
  border-bottom: @border-width-base @border-style-base @gray-color-3;
  padding: 6px @padding-md 5px @padding-lg;
}

.ant-drawer-title {
  display: flex;
  align-items: center;
  min-width: 0;
}

.ant-drawer-header-title {
  min-width: 0;
}

.ant-drawer-close {
  &:extend(.text-color-gray-9);
  min-width: 32px;
  border-radius: 50%;
  padding: 8px;
  margin-left: 8px;

  &:hover {
    background: fade(@gray-color-9, 8%);
  }
}

.ant-drawer-open.no-mask {
  pointer-events: none;

  .ant-drawer-content-wrapper {
    pointer-events: auto;
  }
}

.ant-drawer-footer {
  display: flex;
  align-items: flex-start;
  gap: @margin-sm;
  padding: @padding-xs @padding-lg;
  flex-wrap: wrap;

  .drawer-footer-loading-indicator {
    width: 100%;
  }
}

@media all and (max-width: 1280px) {
  .ant-drawer-content-wrapper {
    flex-direction: column;
    width: var(--drawer-width);

    .ant-drawer-content {
      flex: 1;
    }

    .side-panel {
      height: auto;
      max-height: 50%;
    }

    &.goal-side-panel,
    &.metric-side-panel {
      flex-direction: row;

      .side-panel {
        max-height: inherit;
      }
    }
  }
}
