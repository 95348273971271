@import "../../../../../../node_modules/gridstack/dist/gridstack.css";
@import "../../../../../../node_modules/gridstack/dist/gridstack-extra.css";

.grid-stack-item-content {
  border-radius: 8px;
}

ui-dashboard-item {
  &.size-to-content {
    ui-card {
      height: var(--ui-dashboard-item-height);
    }
  }

  &.size-to-content-max {
    ui-card {
      .ant-card-body {
        overflow-y: auto;
      }
    }
  }
}
