@import (reference)
  "node_modules/@quantive/ui-kit/src/less/default/default.less";

.ant-pagination {
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: @margin-xxs;
  }
}

.ant-pagination-prev {
  margin-right: @margin-ml;
}

.ant-pagination-next {
  margin-left: @margin-ml;
}

.ant-pagination-prev {
  padding: 0 @padding-ml 0 @padding-md;
}

.ant-pagination-next {
  padding: 0 @padding-md 0 @padding-ml;
}

.ant-pagination-item-link,
.ant-pagination-item {
  &:focus-visible {
    .focused-element-outline();
  }
}

.ant-pagination-item-link:not(.ant-pagination-disabled),
.ant-pagination-item-link:not(.ant-pagination-disabled),
.ant-pagination-item {
  &:hover {
    background: fade(@gray-color-9, 8%);
    color: @gray-color-13;
  }
}

.ant-pagination-item,
.ant-pagination-item-link {
  border: 0;
  margin: 0;
  color: @gray-color-9;
  border: none;
  background: none;
  cursor: pointer;
  border-radius: 50%;
  width: 100%;
  font-size: 14px;
}

.ant-pagination-item-active {
  border: 0;
  color: @gray-color-13;
  background: fade(@gray-color-9, 24%);

  &:hover {
    border: 0;
    color: @gray-color-13;
  }
}

.ant-pagination.ant-pagination-disabled {
  .ant-pagination-item,
  .ant-pagination-item-link,
  .ant-pagination-item-active {
    background: none;
    color: @gray-color-7;
  }
}

.ant-pagination-disabled,
.ant-pagination-disabled:hover {
  color: @gray-color-7;
}

.ant-pagination-prev,
.ant-pagination-next {
  border-radius: 20px;
  height: 30px;
  line-height: 30px;
}

.ant-pagination-item-ellipsis {
  color: @gray-color-9;
}

.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  &:hover {
    .ant-pagination-item-ellipsis {
      opacity: unset;
    }
  }
}
