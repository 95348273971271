@import (reference)
  "node_modules/@quantive/ui-kit/src/less/default/default.less";

.okr-status-menu {
  #gh-dropdown-menu.okr-status-dropdown {
    width: 260px;
    button.menu-item-action {
      display: block;

      .description {
        color: @gray-8;
      }
    }
  }
}

.okr-status-tooltip-hide {
  display: none;
}
