@import (reference)
  "node_modules/@quantive/ui-kit/src/less/default/default.less";

.ant-select-item.ant-select-item-option {
  transition: initial; //ant override
  background-color: @gray-color-1; //ant override
  margin: 0 @margin-xxs;
  border: @border-width-base @border-style-base transparent;
  border-radius: @border-radius-md;

  &.hovered-option-item:not(.ant-select-item-option-disabled):not(
      .ant-select-item-option-selected
    ) {
    background-color: @primary-color-1;
  }

  &.activated-option-item:not(.ant-select-item-option-disabled) {
    border: @border-width-base @border-style-base @primary-color-8;
  }
}
.ant-select-dropdown ui-option-item {
  &.ant-select-item-option-selected {
    background-color: @primary-color-8;

    .ant-select-item-option-content {
      color: @gray-color-13;
    }
  }
}

.ant-select-item-option-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  font-family: "SF Pro", sans-serif;
  font-weight: @font-weight-medium;
}

.select-item-text-content-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Those styles would be applied globally in GVS-42061
.insight-list-selector {
  .ant-select-dropdown ui-option-item {
    .ant-select-item-option-content {
      color: @gray-13;
    }

    &.hovered-option-item:not(.ant-select-item-option-disabled):not(
        .ant-select-item-option-selected
      ) {
      background-color: @hov-gray-9;
    }

    &.ant-select-item-option-selected {
      background-color: fade(@gray-color-9, 24%);
    }
  }
}
