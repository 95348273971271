@import (reference)
  "node_modules/@quantive/ui-kit/src/less/default/default.less";

.gh-select-item-active {
  box-shadow: @chip-focus-box-shadow;
}

:host-context(.fixed-width-task-status .ant-select-selection-item) {
  padding-left: 2px;
}

.ant-select-selection-item-remove {
  margin-right: -@margin-xs;
  width: @height-sm;
  height: @height-sm;
  border-radius: 50%;
  align-content: center;
  flex-shrink: 0;

  &:hover {
    background: @gray-color-4;
    color: @gray-color-13;
    cursor: pointer;
  }

  .default-remove-chip-icon {
    font-size: @font-size-base;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
