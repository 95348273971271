@import (reference)
  "node_modules/@quantive/ui-kit/src/less/default/default.less";

.ant-form-horizontal .ant-form-item-control {
  min-width: auto;
}

.ant-form-item-control {
  .ant-form-item-explain {
    margin-top: 4px;
    .ant-form-item-explain-error {
      color: @red-10;
      font-size: 12px;
    }
  }
}
