.ui-link-base() {
  padding: 0 @padding-xxxs;
  border-bottom: @border-width-base @border-style-base transparent;
  font-size: @font-size-base;
  font-weight: @font-weight-semibold;
  color: @primary-color-8;

  &:hover {
    color: @primary-color-10;
    border-color: @primary-color-10;
  }

  &:focus-visible {
    border-radius: @border-radius-base;
    .focused-element-outline-sm();

    &:hover {
      border-color: transparent;
    }
  }
}
