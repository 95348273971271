goal-privacy-button {
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 3px;
    padding-left: 3px;
  }
}
