@import (reference)
  "node_modules/@quantive/ui-kit/src/less/default/default.less";

gh-session-selector {
  &.borderless {
    .ant-select:not(.ant-select-customize-input) {
      .ant-select-selector {
        border: 1px solid transparent;
        border-radius: @border-radius-md;

        &:hover {
          border: 1px solid @gray-color-6;
        }
      }

      &.ant-select-disabled,
      &.tree-select-readonly {
        .ant-select-selector {
          background-color: transparent;
        }
      }
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(
        .ant-select-customize-input
      ) {
      .ant-select-selector {
        border: 1px solid @primary-color-8 !important;
        box-shadow: none;
      }
    }

    // we don't display the dropdown arrow in edit-okr view
    ui-select-arrow {
      .ui-arrow-custom[ui-icon] {
        display: none;
      }
    }

    .ant-select:not(.ant-select-customize-input) {
      .ant-select-selector {
        transition: none;
      }
    }
  }

  &:not(.borderless) {
    .ant-select:not(.ant-select-customize-input) {
      .ant-select-selector {
        border: 1px solid @gray-color-4;
        box-shadow: @input-box-shadow;
        border-radius: @border-radius-md;
        transition: initial;

        &:hover {
          border-color: @gray-color-6 !important;
        }
      }

      &.ant-select-disabled,
      &.tree-select-readonly {
        .ant-select-selector {
          background-color: @gray-color-2;
        }
      }
    }

    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
      border-color: @gray-color-4;
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(
        .ant-select-customize-input
      ) {
      .ant-select-selector {
        border: 1px solid @primary-color-8 !important;
        box-shadow: none;
      }
    }
  }

  .selected-item-color {
    display: inline-block;
    margin-left: @margin-xxxs;
    width: @session-selector-color-diameter;
    height: @session-selector-color-diameter;
    border-radius: @session-selector-color-diameter;
  }

  .ant-select-single.ant-select-open {
    .ant-select-selection-item {
      .selected-item-color {
        opacity: 0.5;
      }
    }
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-select-single:not(.ant-select-customize-input) {
    .ant-select-selector {
      height: @height-lg;
      padding-top: @padding-xxs;
      padding-bottom: @padding-xxs;

      .ant-select-selection-search,
      .ant-select-selection-search input {
        height: @height-lg - 2px; // subtract borders width
        line-height: @height-lg - 2px; // subtract borders width
      }
    }
  }
}

.session-selector-global {
  max-height: @session-selector-max-height;

  .node-title-wrapper {
    display: flex;
    min-width: 0;
    flex: 1;
    justify-content: space-between;
    align-items: center;

    > span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .node-icon {
    margin-left: 8px;
    margin-right: 4px;
    height: @session-selector-color-diameter;
    flex: 0 0 @session-selector-color-diameter;
    border-radius: @session-selector-color-diameter;
  }

  .no-sessions-found {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 28px;
    margin-left: 16px;
  }

  .selected-icon {
    flex: 0 0 16px;
    right: 8px;
  }

  ui-tree-node-title {
    min-width: 0;
  }

  .ant-typography {
    color: unset;
  }

  .ant-tree-node-content-wrapper {
    display: flex;
    min-width: 0;
    align-items: center;
  }

  .ant-select-tree-treenode-selected {
    background: @primary-color-8;
    color: @gray-color-1;
  }

  .ant-select-tree {
    .ant-select-tree-treenode {
      padding: 0;
    }

    .ant-select-tree-treenode-disabled {
      .ant-select-tree-node-content-wrapper {
        cursor: default;
      }
    }

    .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
      background: none;
    }

    .ant-select-tree-node-content-wrapper:hover {
      background: none;
    }
  }
}
