.btn-round(@btnClassName: btn) {
  .button-size(@btn-circle-size; @btn-circle-horizontal-padding; @font-size-base; @btn-circle-size);
  &.@{btnClassName}-lg {
    .button-size(
      @btn-circle-size-lg; @btn-circle-horizontal-padding-lg; @btn-font-size-lg; @btn-circle-size-lg
    );
  }
  &.@{btnClassName}-sm {
    .button-size(
      @btn-circle-size-sm; @btn-circle-horizontal-padding-sm; @btn-font-size-sm; @btn-circle-size-sm
    );
  }
}
