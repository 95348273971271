:host {
  outline: none;
  border: 1px solid transparent;
  border-radius: 4px;

  &:focus-visible {
    border-color: #0057d7;
  }
}

.ant-progress-outer {
  line-height: 1.5715;
}

.ant-progress-text {
  line-height: initial;
}

.progress-flex {
  display: flex;
  align-items: center;
}
